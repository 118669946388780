import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Utils } from '../utils';
import { environment } from 'src/environments/environment';
import { LocalStoreService } from './local-store.service';

@Injectable({
    providedIn: 'root'
})
export class StatistiquesService {
    partner: any;
    constructor(
        private http: HttpClient,
        private store: LocalStoreService,
    ) {
        this.partner = this.store.getItem("adminRaja");
    }

    getAllPublications(skip, type, status) {
        const headers = new HttpHeaders()
            .set('Content-Type', 'application/json').set('X-Parse-Application-Id', environment.parseId);
        var ur = "";

        if (type != "") {

            ur += "\"partner\":{\"$exists\":true},\"type\":\"" + type + "\"";

        }
        if (status != "") {
            if (type != "") {
                ur += ","
            }
            ur += "\"partner\":{\"$exists\":true},\"active\":" + status + "";
        }
        if (type == "" && status == "") {
            ur += "\"partner\":{\"$inQuery\":{\"where\":{\"objectId\":\"" + this.partner.objectId + "\"},\"className\":\"partners\"}}"
        }
        /*where={"partner":{"$inQuery":{"where":{"objectId":"'+partnerId+'"},"className":"partners"}}} */

        return this.http.get<any[]>(environment.baseUrl + 'offers?order=-createdAt&where={' + ur + '}&include=partner&count=1&limit=10&skip=' + skip, { headers });
    }
    getAllPublicationsLimit() {
        const headers = new HttpHeaders()
            .set('Content-Type', 'application/json').set('X-Parse-Application-Id', environment.parseId);
        var ur = "\"partner\":{\"$exists\":true}";

        return this.http.get<any[]>(environment.baseUrl + 'offers?order=-createdAt&where={' + ur + '}&limit=1000000', { headers });
    }
    changeStatus(id, status) {
        const headers = new HttpHeaders()
            .set('Content-Type', 'application/json').set('X-Parse-Application-Id', environment.parseId);
        return this.http.put(
            environment.baseUrl + 'offers/' + id, { "active": status }, { headers });
    }

    getMatchByYear() {
        const headers = new HttpHeaders()
            .set('Content-Type', 'application/json').set('X-Parse-Application-Id', environment.parseId);
        return this.http.get<any[]>(environment.baseUrl + 'connect?limit=30000&where={"accepted":true}', { headers });
    }

    getAllUsers() {
        const headers = new HttpHeaders()
            .set('Content-Type', 'application/json').set('X-Parse-Application-Id', environment.parseId);
        return this.http.get<any[]>(environment.baseUrl + 'users?limit=50000', { headers });
    }
    changeSponsorise(id, status) {
        const headers = new HttpHeaders()
            .set('Content-Type', 'application/json').set('X-Parse-Application-Id', environment.parseId);
        return this.http.put(
            environment.baseUrl + 'offers/' + id, { "sponsorise": status }, { headers });
    }

}
