import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Utils } from '../utils';
import { environment } from 'src/environments/environment';
import { LocalStoreService } from './local-store.service';

@Injectable({
    providedIn: 'root'
})
export class BoutiqueService {

    partner: any;
    constructor(
        private http: HttpClient,
        private store: LocalStoreService,
    ) {
        this.partner = this.store.getItem("adminRaja");
    }

    getProducts(partnerId) {
        const headers = new HttpHeaders()
            .set('Content-Type', 'application/json').set('X-Parse-Application-Id', environment.parseId);

        return this.http.get<any[]>(environment.baseUrl + 'offers?where={"type":"boutique_online","partner":{"$inQuery":{"where":{"objectId":"' + partnerId + '"},"className":"partners"}}}&order=-createdAt&include=category&include=suCat', { headers });
    }
    getProductById(id) {
        const headers = new HttpHeaders()
            .set('Content-Type', 'application/json').set('X-Parse-Application-Id', environment.parseId);
        return this.http.get<any[]>(environment.baseUrl + 'offers/' + id + '?include=category&include=suCat', { headers });
    }

    saveProduct(product) {
        const headers = new HttpHeaders()
            .set('Content-Type', 'application/json').set('X-Parse-Application-Id', environment.parseId);

        const productToSave = Object.assign({});

        productToSave.name = product.titre;
        productToSave.summary = product.shortDescription;
        productToSave.description = product.description;
        productToSave.productUrl = product.productUrl;
        productToSave.pictures = product.pictures;
        productToSave.initialPrice = product.prixInitial;
        productToSave.sellingPrice = product.prixDeVente;
        productToSave.rate = Math.trunc(100 - ((product.prixDeVente / product.prixInitial) * 100));
        productToSave.address = product.adresse;
        productToSave.quantity = product.quantite;
        productToSave.active = 1;
        productToSave.communitiesKey = product.communities;
        productToSave.type = 'boutique_online';
        var partner = new Map();
        partner["__type"] = "Pointer";
        partner["className"] = "partners";
        partner["objectId"] = this.partner.objectId;
        productToSave.partner = partner;
       /*  var category = new Map();
        category["__type"] = "Pointer";
        category["className"] = "category_shop";
        category["objectId"] = product.categorie;
        productToSave.category = category;

        var suCat = new Map();
        suCat["__type"] = "Pointer";
        suCat["className"] = "ss_category_shop";
        suCat["objectId"] = product.subCategorie;
        productToSave.suCat = suCat;
 */

        if (product.id) {
            return this.http.put<any[]>(environment.baseUrl + 'offers/' + product.id, productToSave, { headers });
        } else {
            return this.http.post<any[]>(environment.baseUrl + 'offers', productToSave, { headers });
        }
    }
    deleteProduct(id) {
        const headers = new HttpHeaders()
            .set('Content-Type', 'application/json').set('X-Parse-Application-Id', environment.parseId);

        return this.http.delete<any[]>(environment.baseUrl + 'offers/' + id, { headers });
    }

    getBoutiqueCategories() {
        const headers = new HttpHeaders()
            .set('Content-Type', 'application/json').set('X-Parse-Application-Id', environment.parseId);
        return this.http.get<any[]>(environment.baseUrl + 'category_shop?include=subcategory', { headers })

    }

    findAllBoutiqueCategories() {
        const headers = new HttpHeaders()
            .set('Content-Type', 'application/json').set('X-Parse-Application-Id', environment.parseId);

        return this.http.get<any[]>(environment.baseUrl + 'category_shop?include=subcategory&order=-createdAt', { headers });
    }

    deleteCatBoutique(id) {
        const headers = new HttpHeaders()
            .set('Content-Type', 'application/json').set('X-Parse-Application-Id', environment.parseId);

        return this.http.delete<any[]>(environment.baseUrl + 'category_shop/' + id, { headers });
    }

    deleteSubCatBoutique(id) {
        const headers = new HttpHeaders()
            .set('Content-Type', 'application/json').set('X-Parse-Application-Id', environment.parseId);

        return this.http.delete<any[]>(environment.baseUrl + 'ss_category_shop/' + id, { headers });
    }

    saveBoutiqueCategory(cat) {
        const headers = new HttpHeaders()
            .set('Content-Type', 'application/json').set('X-Parse-Application-Id', environment.parseId);
        const catToSave = Object.assign({});
        catToSave.name = cat.name;
        catToSave.name_ar = cat.name_ar;
        catToSave.clr = cat.clr.split('#')[1];
        catToSave.icon = cat.icon;
        catToSave.type = "category";
        catToSave.subcategory = [];


        return this.http.post<any[]>(environment.baseUrl + 'category_shop', catToSave, { headers });
    }
    putBoutiqueCategory(cat) {
        const headers = new HttpHeaders()
            .set('Content-Type', 'application/json').set('X-Parse-Application-Id', environment.parseId);
        const catToSave = Object.assign({});
        catToSave.name = cat.name;
        catToSave.name_ar = cat.name_ar;
        catToSave.icon = cat.icon;
        catToSave.clr = cat.clr.split('#')[1]


        return this.http.put<any[]>(environment.baseUrl + 'category_shop/' + cat.id, catToSave, { headers });
    }
    saveBoutiqueSubCategory(cat) {
        const headers = new HttpHeaders()
            .set('Content-Type', 'application/json').set('X-Parse-Application-Id', environment.parseId);
        const catToSave = Object.assign({});
        catToSave.name = cat.name;
        catToSave.name_ar = cat.name_ar;
        catToSave.clr = cat.clr.split('#')[1]
        catToSave.icon = cat.icon;
        catToSave.type = "subCategory";


        return this.http.post<any[]>(environment.baseUrl + 'ss_category_shop', catToSave, { headers });
    }
    putBoutiqueSubCategory(cat) {
        const headers = new HttpHeaders()
            .set('Content-Type', 'application/json').set('X-Parse-Application-Id', environment.parseId);
        const catToSave = Object.assign({});
        catToSave.name = cat.name;
        catToSave.name_ar = cat.name_ar;
        catToSave.icon = cat.icon;
        catToSave.clr = cat.clr.split('#')[1]


        return this.http.put<any[]>(environment.baseUrl + 'ss_category_shop/' + cat.id, catToSave, { headers });
    }

    updateCategoryShop(idCat, idSubCat) {

        const headers = new HttpHeaders()
            .set('Content-Type', 'application/json').set('X-Parse-Application-Id', environment.parseId);

        return this.http.put<any[]>(
            environment.baseUrl + 'category_shop/' + idCat,
            {
                'subcategory': {
                    __op: 'AddUnique',
                    objects: [{
                        '__type': 'Pointer',
                        'className': 'ss_category_shop',
                        'objectId': idSubCat
                    }]
                }
            }, { headers });

    }

}
