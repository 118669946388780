import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Utils } from '../utils';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class AnnonceService {

    constructor(
        private http: HttpClient,
    ) { }

    getAllAnnonces(skip, status, type) {
        const headers = new HttpHeaders()
            .set('Content-Type', 'application/json').set('X-Parse-Application-Id', environment.parseId);

        var ur = "";
        var qu = [
            { "type": "Achat / Vente" },
            { "type": "Général" },
            { "type": "sondage" },
            { "type": "Mission" }
        ];
        if (type == "" && status == "") {
            ur = "\"\$or\":" + JSON.stringify(qu);
        }
        if (status != "") {
            if (type != "") {
                if (status == "0") {
                    ur += "\"active\":0,\"type\":\"" + type + "\"";
                }

                else {
                    ur += "\"active\":1,\"type\":\"" + type + "\"";
                }
            }
            else {
                if (status == "0") {
                    ur += "\"active\":0,\"\$or\":" + JSON.stringify(qu);
                }

                else {
                    ur += "\"active\":1,\"\$or\":" + JSON.stringify(qu);
                }
            }
        }
        if (type != "" && status == "") {
            ur += "\"type\":" + '"' + type + '"';
        }


        return this.http.get<any[]>(environment.baseUrl + 'offers?where={' + ur + '}&include=author&include=options&order=-createdAt&limit=10&count=1&skip=' + skip, { headers });
    }

    changeStatus(id, status) {
        const headers = new HttpHeaders()
            .set('Content-Type', 'application/json').set('X-Parse-Application-Id', environment.parseId);

        return this.http.put(
            environment.baseUrl + 'offers/' + id, { "active": status }, { headers });
    }

    deleteAnnonce(id) {
        const headers = new HttpHeaders()
            .set('Content-Type', 'application/json').set('X-Parse-Application-Id', environment.parseId);

        return this.http.delete<any[]>(environment.baseUrl + 'offers/' + id, { headers });
    }



}
