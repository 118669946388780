import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Utils } from '../utils';
import { environment } from 'src/environments/environment';
import { LocalStoreService } from './local-store.service';
import { Partner } from '../models/partner.model';

@Injectable({
    providedIn: 'root'
})
export class PartnersService {

    constructor(
        private http: HttpClient,
    ) {

    }

    getPartners(skip, status, sponsorise) {
        const headers = new HttpHeaders()
            .set('Content-Type', 'application/json').set('X-Parse-Application-Id', environment.parseId);

        var ur = "";

        if (status != "") {
            if (status == "0") {
                ur += "\"status\":\"0\"";
            }

            else {
                ur += "\"status\":\"1\"";
            }
        }
        if (sponsorise != "") {
            if (status != "") {
                ur += ","
            }
            if (sponsorise == "0") {
                ur += "\"sponsorise\":0";
            }

            else {
                ur += "\"sponsorise\":1";
            }
        }
        return this.http.get<any[]>(environment.baseUrl + 'partners?where={' + ur + '}&order=-createdAt&count=1&limit=10&skip=' + skip, { headers });
    }

    getPartnersLimit(limit, status, sponsorise, type) {
        const headers = new HttpHeaders()
            .set('Content-Type', 'application/json').set('X-Parse-Application-Id', environment.parseId);

        var ur = "";

        if (status != "") {
            if (status == "0") {
                ur += "\"status\":\"0\"";
            }

            else {
                ur += "\"status\":\"1\"";
            }
        }
        if (sponsorise != "") {
            if (status != "") {
                ur += ","
            }
            if (sponsorise == "0") {
                ur += "\"sponsorise\":0";
            }

            else {
                ur += "\"sponsorise\":1";
            }
        }
        if (type != "") {
            if (status != "" || sponsorise != "") {
                ur += ","
            }
            ur += "\"type\":" + '"' + type + '"';
        }

        return this.http.get<any[]>(environment.baseUrl + 'partners?where={' + ur + '}&order=-createdAt&count=1&limit=' + limit, { headers });
    }



    getPartnerById(id) {
        const headers = new HttpHeaders()
            .set('Content-Type', 'application/json').set('X-Parse-Application-Id', environment.parseId);

        return this.http.get<any[]>(environment.baseUrl + 'partners/' + id + '?include=sectorr', { headers });
    }

    changeStatus(id, status) {
        const headers = new HttpHeaders()
            .set('Content-Type', 'application/json').set('X-Parse-Application-Id', environment.parseId);

        return this.http.put(
            environment.baseUrl + 'partners/' + id, { "status": status }, { headers });
    }

    changeSponsore(id, sponsorise) {
        const headers = new HttpHeaders()
            .set('Content-Type', 'application/json').set('X-Parse-Application-Id', environment.parseId);

        return this.http.put(
            environment.baseUrl + 'partners/' + id, { "sponsorise": sponsorise }, { headers });
    }


    sendMail(objet, message, emails) {
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.post<any[]>(
            "https://us-central1-sante-ae15e.cloudfunctions.net/sendCustomMailToUserRaja",
            { subject: objet, message: message, email: emails }, { headers });
    }


    savePartner(partner) {
        const headers = new HttpHeaders()
            .set('Content-Type', 'application/json').set('X-Parse-Application-Id', environment.parseId);

        const partnerToSave = Object.assign({});

        partnerToSave.name = partner.nom;
        partnerToSave.type = partner.type;
        partnerToSave.description = partner.description;
        partnerToSave.phoneNumber = partner.telephone;
        partnerToSave.logo = partner.logo == '' ? "https://res.cloudinary.com/dgxctjlpx/image/upload/v1580829654/Rcalogo_cgwbrz.png" : partner.logo;
        partnerToSave.email = partner.email;
        partnerToSave.directName = partner.nomDirecteur;
        partnerToSave.address = partner.adresse;
        partnerToSave.structure = partner.structure;

        var secteur = new Map();
        secteur["__type"] = "Pointer";
        secteur["className"] = "sectors";
        secteur["objectId"] = partner.secteur;
        partnerToSave.sectorr = secteur;

        
        if (partner.id) {
            return this.http.put<any[]>(environment.baseUrl + 'partners/' + partner.id, partnerToSave, { headers });
        } else {
            partnerToSave.status = "0";
            partnerToSave.sponsorise = 0;
            partnerToSave.raja = false;
            return this.http.post<any[]>(environment.baseUrl + 'partners', partnerToSave, { headers });
        }
    }

    createPartnerAccount(data, id) {
        const headers = new HttpHeaders()
            .set('Content-Type', 'application/json').set('X-Parse-Application-Id', environment.parseId);

        return this.http.post<any[]>('http://5.196.23.101:1378/parse/users',
            { "username": data.nom, "password": "123456", "email": data.email, "status": true, "fullName": data.nom, "role": "partenaire", "photoUrl": data.logo, "partnerId": id }, { headers });

    }
    desactivatePartnerAccount(id) {
        const headers = new HttpHeaders()
            .set('Content-Type', 'application/json').set('X-Parse-Application-Id', environment.parseId);

        return this.http.put<any[]>('http://5.196.23.101:1378/parse/users/' + id,
            { "status": false }, { headers });
    }

    deletePartnerSessions(id) {
        const headers = new HttpHeaders()
            .set('Content-Type', 'application/json').set('X-Parse-Application-Id', environment.parseId);

        return this.http.put<any[]>('http://5.196.23.101:1378/parse/users?where={"partnerId":"' + id + '"}',
            { "status": false }, { headers });
    }

}
